// import { reactive, computed, readonly } from 'vue';
import firebase from 'firebase/app';
import 'firebase/auth';

const provider = new firebase.auth.GoogleAuthProvider();
const signin = async () => {
  return firebase.auth().signInWithPopup(provider);
}

const signout = async () => {
  return firebase.auth().signOut();
}

export {
  signin,
  signout,
};