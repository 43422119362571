<template>
  <div v-if="statsStore.hasStats" class="global-stats">
    <h2>Global stats {{statsStore.state.highscoreYear}}</h2>
    <div class="player-stats" v-if="statsStore.hasPlayersStats">
      <h3>Players</h3>
      <ul>
        <li>Registered players: <strong>{{statsStore.allPlayers.length}}</strong></li>
        <li>Newest member: <strong>{{statsStore.lastToJoin.displayName}}</strong>, Joined {{statsStore.lastToJoin.joined.toDate().toDateString()}}</li>  
      </ul>
      <!-- <h3>Last player to join: {{statsStore.lastToJoin.displayName}}</h3>
      <span>Joined: {{statsStore.lastToJoin.joined.toDate().toDateString()}}</span>
      <p>There are {{statsStore.allPlayers.length}} registered players</p>
      <h3>Players stats data dump</h3>
      <span><pre>{{JSON.stringify(statsStore.state.playersDoc, null, 2)}}</pre></span> -->
    </div>
    <div class="game-stats" v-if="statsStore.hasGamesStats">
      <h3>Games</h3>
      <ul>
        <li>Total games played: <strong>{{statsStore.totalGames}}</strong></li>
        <li vif="statsStore.singles">Singles games played: <strong>{{statsStore.singles.totalGames}}</strong></li>
        <li vif="statsStore.doubles">Doubles games played: <strong>{{statsStore.doubles.totalGames}}</strong></li>
        <li>Total points played: <strong>{{statsStore.totalPoints}}</strong> ({{Math.round((statsStore.gamesWithScore / (statsStore.totalGames * 1.0)) * 100)}}% of registered games have a score)</li>
      </ul>
      <template v-if="statsStore.highscoreGames.length > 0">
        <h3>Highscore (total games played)</h3>
        <ul>
          <li v-for="item in statsStore.highscoreGames.slice(0, 10)" :key="item.id">
            {{ item.displayName }} <strong>{{item.value}}</strong>
          </li>
        </ul>
      </template>
      <template v-if="statsStore.highscoreWins.length > 0">
        <h3>Highscore (total games won)</h3>
        <ul>
          <li v-for="item in statsStore.highscoreWins.slice(0, 10)" :key="item.id">
            {{ item.displayName }} <strong>{{item.value}}</strong>
          </li>
        </ul>
      </template>
      <template v-if="statsStore.highscorePoints.length > 0">
        <h3>Highscore (total points won)</h3>
        <ul>
          <li v-for="item in statsStore.highscorePoints.slice(0, 10)" :key="item.id">
            {{ item.displayName }} <strong>{{item.value}}</strong>
          </li>
        </ul>
      </template>
      <template v-if="statsStore.highscoreRatio.length > 0">
        <h3>Highscore (win to loss ratio)</h3>
        <ul>
          <li v-for="item in statsStore.highscoreRatio.slice(0, 10)" :key="item.id">
            {{ item.displayName }} <strong>{{Math.round(item.value * 100)}}%</strong>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    inject: ['playerStore', 'statsStore'],
  }
</script>

<style scoped lang="scss">
.global-stats {
  
}
.highscore {
  li {
    display: flex;

    & > * {
      flex: 1 1 0px
    }
  }
}
</style>