/**
 * Stats by year is hardcoded here.
 * Initial version did not support multiple years, so that has been patched on top. Needs Refactoring
 * Detailed data is only available for current year (VUE_APP_STATS_YEAR)
 */


/*eslint-disable no-unused-vars*/
import { reactive, computed, readonly } from 'vue';
import firebase from 'firebase/app';
import 'firebase/firestore';
// import merge from 'lodash/merge';
// import spread from 'lodash/spread';
// import groupBy from 'lodash/groupBy';
import _ from 'lodash';
import { getCollectionPath, getCollectionPathForYear } from '@/utils/dataPathResolver'

/* #region STATE */
const state = reactive({
  playersDoc: null,
  gamesDoc: null,
  gamesDoc2021: null, // initial year, before multi year support
  highscoreYear: process.env.VUE_APP_STATS_YEAR ? process.env.VUE_APP_STATS_YEAR : 2022 // 2022 is first year with multi year structure
});

let unsubscribePlayersStats = null;
let unsubscribeGamesStats = null;
let unsubscribeGamesStats2021 = null;
/* #endregion */

const minimumGamesPlayedForHighscore = 5;

/* #region LISTENERS */
const init = () => {
  const db = firebase.firestore();
  const playersDocRef = db.collection(getCollectionPath('stats')).doc('players');
  const gamesDocRef = db.collection(getCollectionPath('stats')).doc('games');
  const gamesDocRef2021 = db.collection(getCollectionPathForYear('stats', 2021)).doc('games');

  unsubscribePlayersStats = playersDocRef.onSnapshot((doc) => {
    // console.log('Player stats data change listener: ', doc.data());
    state.playersDoc = doc.data();
  });

  unsubscribeGamesStats = gamesDocRef.onSnapshot((doc) => {
    // console.log('Games stats data change listener: ', doc.data());
    state.gamesDoc = doc.data();
  });

  unsubscribeGamesStats2021 = gamesDocRef2021.onSnapshot((doc) => {
    state.gamesDoc2021 = doc.data();
  });
};

const destroy = () => {
  if (unsubscribePlayersStats) {
    unsubscribePlayersStats();
  }

  if (unsubscribeGamesStats) {
    unsubscribeGamesStats();
  }

  if (unsubscribeGamesStats2021) {
    unsubscribeGamesStats2021();
  }
};
/* #endregion */

/* #region COMPUTED */
const hasPlayersStats = computed(() => state.playersDoc ? true : false)
const hasGamesStats = computed(() => state.gamesDoc ? true : false)
const lastToJoin = computed(() => state.playersDoc ? state.playersDoc.lastToJoin : false)
const allPlayers = computed(() => state.playersDoc ? state.playersDoc.players : [])
const totalGames = computed(() => state.gamesDoc ? state.gamesDoc.totalGames : 0)
const totalPoints = computed(() => state.gamesDoc ? state.gamesDoc.totalPoints : 0)
const gamesWithScore = computed(() => state.gamesDoc ? state.gamesDoc.singles.gamesWithScore + state.gamesDoc.doubles.gamesWithScore : 0)
const highscoreGames = computed(() => {
  if (state.gamesDoc) {
    const highscore = state.gamesDoc.highscoreGames
    highscore.sort((a, b) => b.value - a.value || a.displayName.localeCompare(b.displayName))
    return highscore
  }
  return []
})

const highscoreWins = computed(() => {
  if (state.gamesDoc) {
    const highscore = state.gamesDoc.highscoreWins
    highscore.sort((a, b) => b.value - a.value || a.displayName.localeCompare(b.displayName))
    return highscore
  }
  return []
})
const highscorePoints = computed(() => {
  if (state.gamesDoc) {
    const highscore = state.gamesDoc.highscorePoints
    highscore.sort((a, b) => b.value - a.value || a.displayName.localeCompare(b.displayName))
    return highscore
  }
  return []
})
const highscoreRatio = computed(() => {
  console.log('Get Ratio highscore')
  if (state.gamesDoc) {
    let highscore = state.gamesDoc.highscoreRatio
    highscore.sort((a, b) => b.value - a.value || a.displayName.localeCompare(b.displayName))
    highscore = filterByGameCount(highscore, state.gamesDoc)
    return highscore
  }
  return []
})
const hasStats = computed(() => state.gamesDoc || state.playersDoc)
const singles = computed(() => state.gamesDoc ? state.gamesDoc.singles : false);
const doubles = computed(() => state.gamesDoc ? state.gamesDoc.doubles : false);

const highscoreFull = computed(() => {
  let gamesDoc
  switch(state.highscoreYear) {
    case 2021:
      gamesDoc = state.gamesDoc2021
      break
    case 2022:
    default:
      gamesDoc = state.gamesDoc
      break
  }

  if (!gamesDoc) {
    return []
  }
 
  const points = gamesDoc.highscorePoints.map(entry => ({ id: entry.id, displayName: entry.displayName, photoURL: entry.photoURL, points: entry.value }))
  points.sort((a, b) => b.points - a.points || a.displayName.localeCompare(b.displayName))
  const games = gamesDoc.highscoreGames.map(entry => ({ id: entry.id, displayName: entry.displayName, photoURL: entry.photoURL, games: entry.value }))
  games.sort((a, b) => b.games - a.games || a.displayName.localeCompare(b.displayName))
  const wins = gamesDoc.highscoreWins.map(entry => ({ id: entry.id, displayName: entry.displayName, photoURL: entry.photoURL, wins: entry.value }))
  wins.sort((a, b) => b.wins - a.wins || a.displayName.localeCompare(b.displayName))
  const ratio = filterByGameCount(gamesDoc.highscoreRatio, gamesDoc).map(entry => ({ id: entry.id, displayName: entry.displayName, photoURL: entry.photoURL, ratio: entry.value }))
  ratio.sort((a, b) => b.ratio - a.ratio || a.displayName.localeCompare(b.displayName))
  /*
  if (!state.gamesDoc) {
    return []
  }
 
  const points = state.gamesDoc.highscorePoints.map(entry => ({ id: entry.id, displayName: entry.displayName, photoURL: entry.photoURL, points: entry.value }))
  points.sort((a, b) => b.points - a.points || a.displayName.localeCompare(b.displayName))
  const games = state.gamesDoc.highscoreGames.map(entry => ({ id: entry.id, displayName: entry.displayName, photoURL: entry.photoURL, games: entry.value }))
  games.sort((a, b) => b.games - a.games || a.displayName.localeCompare(b.displayName))
  const wins = state.gamesDoc.highscoreWins.map(entry => ({ id: entry.id, displayName: entry.displayName, photoURL: entry.photoURL, wins: entry.value }))
  wins.sort((a, b) => b.wins - a.wins || a.displayName.localeCompare(b.displayName))
  const ratio = filterByGameCount(state.gamesDoc.highscoreRatio).map(entry => ({ id: entry.id, displayName: entry.displayName, photoURL: entry.photoURL, ratio: entry.value }))
  ratio.sort((a, b) => b.ratio - a.ratio || a.displayName.localeCompare(b.displayName))
  */
  
  // get combined score. Ranking in each category added together for a total
  let ranking = {} // points.map(entry => {return {id: entry.id, rank:0}})
  points.forEach((item, index) => {
    // console.log(item.id, index)
    ranking[item.id] = index
  })
  games.forEach((item, index) => {
    ranking[item.id] += index
  })
  wins.forEach((item, index) => {
    ranking[item.id] += index
  })
  ratio.forEach((item, index) => {
    ranking[item.id] += index
  })

  const rankingValues = [...new Set(_.values(ranking).sort((a, b) => a - b))]
  // console.log(rankingValues)

  // Merge
  let result = _(points)
  .concat(games, wins, ratio)
  .groupBy('id')
  .map(_.spread(_.merge))
  .value()
  .map(value => {
    return _.assign({ ratio: -1, rank: rankingValues.indexOf(ranking[value.id]) + 1 }, value);
  })
  // .map(value => {
  //   return {...value, rank: ranking[value.id]}
  // })

  return result
})

/* #endregion */

/* #region HELPERS */
const filterByGameCount = (highscore, gamesDoc) => {
  // if (!state.gamesDoc) {
  if(!gamesDoc) {
    return highscore
  }
  const highscoreGames = gamesDoc.highscoreGames
  highscore = highscore.filter(user => {
    const games = highscoreGames.find(gamesUser => {
      return gamesUser.id === user.id
    })
    return (games && games.value >= minimumGamesPlayedForHighscore)
  });
  return highscore
}

const setHighscoreYear = (year) => {
  state.highscoreYear = year
}
/* #endregion */

export const statsStore = readonly({
  state,
  init,
  destroy,
  hasPlayersStats,
  hasGamesStats,
  lastToJoin,
  allPlayers,
  totalGames,
  totalPoints,
  gamesWithScore,
  highscoreGames,
  highscoreWins,
  highscorePoints,
  highscoreRatio,
  highscoreFull,
  hasStats,
  singles,
  doubles,
  setHighscoreYear,
});
