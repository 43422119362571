<template>
  <!-- Other components handle signout -->
  <!-- <div class="signout authentication" v-if="playerStore.isLoggedIn">
    <p>Hello {{playerStore.state.user.displayName}}</p>
    <button v-on:click="signout" >Sign out</button>
  </div> -->
  <div class="signin authentication" v-if="!playerStore.isLoggedIn">
    <div v-if="playerStore.hasError">
        <p>Sign in failed. Make sure you're using a manyone email</p>
        <p class="error">{{playerStore.errorMessage}}</p>
        <button v-on:click="signout">Retry</button>
      </div>
      <div v-else>
        <p class="sign-in-message" v-if="message">{{message}}</p>
        <button v-on:click="signin">Sign in</button>
    </div>
  </div>
</template>

<script>
  import { signin, signout } from '../api/authentication'

  export default {
    inject: ['playerStore'],
    props: {
      message: String,
    },
    setup() {
      return {
        signin,
        signout
      }
    }
  }
</script>

<style lang="scss">
 /* @import "./scss/_variables.scss"; // $text-primary would be defined in that file */

 .authentication {
   .sign-in-message {
     margin-block-end: 0.5em;
   }
 }

 .error {
  color: #e92566;
}
</style>