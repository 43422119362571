import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
// import { playerStore } from '../store/playerStore'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/play',
    name: 'Play',
    component: () => import(/* webpackChunkName: "game" */ '../views/Play.vue')
  },
  // {
  //   path: '/slackbot-test',
  //   name: 'SlackbotTest',
  //   component: () => import(/* webpackChunkName: "game" */ '../views/SlackbotTest.vue')
  // },

  {
    path: '/highscore',
    name: 'Highscore',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Highscore.vue')
  },
]

// Dummy data route only in development
// if (process.env.NODE_ENV === 'development') {
//   routes.push({
//     path: '/dummy-data',
//     name: 'DummyData',
//     component: () => import(/* webpackChunkName: "game" */ '../views/DummyData.vue')
//   },)
// }

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// Handle state in each view instead.
// router.beforeEach((to, from, next) => {
//   if (to.name !== 'Home' && !playerStore.isLoggedIn) next({ name: 'Home' })
//   else next()
// })

export default router
