const config = {
  firebase: {
    apiKey: process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_FIREBASE_API_KEY : process.env.VUE_APP_FIREBASE_API_KEY_STAGING,
    appId: process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_FIREBASE_APP_ID : process.env.VUE_APP_FIREBASE_APP_ID_STAGING,
    authDomain: process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_FIREBASE_AUTH_DOMAIN : process.env.VUE_APP_FIREBASE_AUTH_DOMAIN_STAGING,
    messagingSenderId: process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID : process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID_STAGING,
    projectId: process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_FIREBASE_PROJECT_ID : process.env.VUE_APP_FIREBASE_PROJECT_ID_STAGING,
    storageBucket: process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_FIREBASE_STORAGE_BUCKET : process.env.VUE_APP_FIREBASE_STORAGE_BUCKET_STAGING,
  },
  slack_webhook_url: process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_SLACK_WEBHOOK_CPH_PONG : process.env.VUE_APP_SLACK_WEBHOOK_TEST,
}
export {
  config
}