<template>
  <div class="home">
    <h1>Welcome to ManyPong BETA</h1>
    <!-- <p>Logged in: {{playerStore.isLoggedIn ? 'true' : 'false'}}</p> -->
    <p>ManyPong is here to keep track of the pong game.</p>
    <sign-in message="Sign in with your manyone email to join"/>
    <profile />
    <global-stats />
  </div>
</template>

<script>
  import SignIn from '@/components/SignIn'
  import Profile from '@/components/Profile'
  import GlobalStats from '@/components/GlobalStats'
  export default {
    // inject: ['playerStore'],
    components: {
      'sign-in': SignIn,
      'global-stats': GlobalStats,
      Profile,
    },
  }
</script>