<template>
  <!-- <ul v-if="playerStore.isLoggedIn"> -->
  <ul>
    <li><router-link to="/">Home</router-link></li>
    <li><router-link to="/play">Play  </router-link></li>
    <li><router-link to="/highscore">Highscore</router-link></li>
    <li><router-link to="/about">About</router-link></li>
    <li><mini-profile /></li>
  </ul>
</template>

<script>
  import MiniProfile from '@/components/MiniProfile'
  export default {
    components: {
      'mini-profile': MiniProfile,
    },
    inject: ['playerStore']
  }
</script>

<style scoped lang="scss">
ul {
  list-style-type: none;
  display: flex;
  align-items: center;

  li:not(:last-of-type) {
    margin-right: 10px;
  }

  li:last-child {
    margin-left: auto;
  }
}
img.profile-picture {
  width: 30px;
  height: 30px;
  border-radius: 999px;
}
</style>