import { createApp } from 'vue'
import firebase from 'firebase/app';
import 'firebase/auth';
import App from './App.vue'
import router from './router'
import { config } from './config'
import { playerStore } from './store/playerStore'
import { statsStore } from './store/statsStore'
import './style/global.scss'

firebase.initializeApp(config.firebase);

console.log('%c Welcome to Many-Pong! ', 'background: #6f74e6; color: #a3e2fe ');
console.log(`App version is ${process.env.VUE_APP_VERSION} and it is running in '${process.env.VUE_APP_ENV}' mode, using data for ${process.env.VUE_APP_STATS_YEAR}`)

firebase.auth().onAuthStateChanged(async (user) => {
  // console.log('onAuthStateChanged', user)
  playerStore.setUser(user)
});

statsStore.init()

createApp(App).use(router).mount('#app') // Causes redirect to home since user auth has not finished