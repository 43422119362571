<template>
  <div class="player-stats">
    <ul>
      <li>Joined: <strong>{{playerStore.joined}}</strong></li>
      <li>Last game played: <strong>{{playerStore.lastGame}}</strong></li>
      <!-- <li>Email: <strong>{{playerStore.email}}</strong></li>
      <li>Picture: <strong>{{playerStore.profilePicture}}</strong></li>
      <li>Partners: <strong>{{JSON.stringify(playerStore.partners)}}</strong></li>
      <li>Opponents: <strong>{{JSON.stringify(playerStore.opponents)}}</strong></li>
      <li>Singles: <strong>{{JSON.stringify(playerStore.singles)}}</strong></li>
      <li>Doubles: <strong>{{JSON.stringify(playerStore.doubles)}}</strong></li> -->
    </ul>
    <h3>Singles games</h3>
    <ul>
      <li>Games (won/played): <strong>{{playerStore.singles.won}} / {{playerStore.singles.played}}</strong></li>
      <li>Points (won-lost): <strong>{{playerStore.singles.pointsWon}} - {{playerStore.singles.pointsLost}}</strong></li>
    </ul>
    <h3>Doubles games</h3>
    <ul>
      <li>Games (won/played): <strong>{{playerStore.doubles.won}} / {{playerStore.doubles.played}}</strong></li>
      <li>Points (won-lost): <strong>{{playerStore.doubles.pointsWon}} - {{playerStore.doubles.pointsLost}}</strong></li>
    </ul>
    <template v-if="playerStore.opponents.length > 0 || playerStore.partners.length > 0">
      <h3>Social</h3>
      <ul>
        <li v-if="playerStore.opponents.length > 0">Favorite Opponent: <strong>{{playerStore.opponents[0].displayName}}</strong> ({{playerStore.opponents[0].count}} games)</li>
        <li v-if="playerStore.partners.length > 0">Favorite Partner: <strong>{{playerStore.partners[0].displayName}}</strong> ({{playerStore.partners[0].count}} games)</li>
      </ul>
    </template>
  </div>
</template>

<script>
  export default {
    inject: ['playerStore'],
  }
</script>

<style scoped lang="scss">
.player-stats {
  
}
</style>