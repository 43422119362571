<template>
  <div v-if="playerStore.isLoggedIn" class="profile">
    <header>
      <!-- <h2>{{playerStore.state.user.displayName}}</h2>
      <img class="profile-picture" :src="playerStore.state.user.photoURL" /> -->
      <h2>Your profile | {{playerStore.state.user.displayName}}</h2>
    </header>
    <player-stats />
  </div>
</template>

<script>
  import PlayerStats from '@/components/PlayerStats'
  export default {
    components: {
      'player-stats': PlayerStats,
    },
    inject: ['playerStore'],
  }
</script>

<style scoped lang="scss">
.profile {

  header {
    /* display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 10px;
    } */
  }
}
img.profile-picture {
  width: 30px;
  height: 30px;
  border-radius: 999px;
}
</style>