/*eslint-disable no-unused-vars*/
import { reactive, computed, readonly } from 'vue';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { postSlackMessageWelcome } from '@/api/slack'
import { getCollectionPath } from '@/utils/dataPathResolver'

/* #region STATE */
const initialState = {
  user: null,
  doc: null,
  error: false,
};

const additionalUserFields = {
  partners: [],
  opponents: [],
  singles: {
    played: 0,
    won: 0,
    pointsWon: 0,
    pointsLost: 0,
  },
  doubles: {
    played: 0,
    won: 0,
    pointsWon: 0,
    pointsLost: 0,
  },
  latestGame: null,
};

const state = reactive({ ...initialState });

const resetState = () => {
  Object.assign(state, initialState);
};

let unsubscribe = null;
/* #endregion */

/* #region DB */
const setUser = async (user) => {
  resetState();
  state.user = user;

  if (user) {
    const docRef = await getPlayerFromDB(user);
    if (docRef) {
      unsubscribe = docRef.onSnapshot((doc) => {
        // console.log('Player data change listener: ', doc.data())
        state.doc = doc.data()
      });
    }
  } else {
    if (unsubscribe) {
      unsubscribe();
    }
  }
};

const getPlayerFromDB = async (user) => {
  try {
    const db = firebase.firestore();
    const docRef = db.collection(getCollectionPath('players')).doc(user.uid);
    let doc = await docRef.get();
    if (!doc.exists) {
      await addPlayerToDB(user);
      postSlackMessageWelcome(user.displayName)
    } else {
      await docRef.update({
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
      });
    }
    return docRef;
  } catch (error) {
    console.error(
      'getUserFromDB ERROR',
      error.code,
      error.name,
      error.message,
      JSON.stringify(error, Object.getOwnPropertyNames(error))
    );
    state.error = error;
    return null;
  }
};

const addPlayerToDB = async (user) => {
  const db = firebase.firestore();
  const playerDocRef = db.collection(getCollectionPath('players')).doc(user.uid);
  const playerStatsRef = db.collection(getCollectionPath('stats')).doc('players');
  
  const result = await db.runTransaction(async (transaction) => {
    try {
      // const playerDoc = await transaction.get(playerDocRef);
      const playerStatsDoc = await transaction.get(playerStatsRef);

      // set player doc
      const playerData = {
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        ...additionalUserFields,
      };
      transaction.set(playerDocRef, playerData);
      
      // set / update player stats doc
      if(playerStatsDoc.exists) {
        transaction.update(playerStatsRef, {
          // count: firebase.firestore.FieldValue.increment(1), // redundant: players.length 
          lastToJoin: {
            id: user.uid,
            displayName: user.displayName,
            joined: firebase.firestore.FieldValue.serverTimestamp(),
          },
          players: firebase.firestore.FieldValue.arrayUnion({
            id: user.uid,
            displayName: user.displayName,
          }),
        })
      } else {
        transaction.set(playerStatsRef, {
          // count: 1, // redundant: players.length 
          lastToJoin: {
            id: user.uid,
            displayName: user.displayName,
            joined: firebase.firestore.FieldValue.serverTimestamp(),
          },
          players: [{
            id: user.uid,
            displayName: user.displayName,
          }],
        })
      }

      return true;
    } catch (error) {
      console.error(
        'addPlayerToDB ERROR',
        error.code,
        error.name,
        error.message,
        JSON.stringify(error, Object.getOwnPropertyNames(error))
      );
      return false;
    }
  });

  if(result) {
    return playerDocRef
  }

  return null
};
/* #endregion */

/* #region COMPUTED */

const name = computed(() => state.doc ? state.doc.displayName : '');
const email = computed(() => state.doc ? state.doc.email : '');
const profilePicture = computed(() => state.doc ? state.doc.photoURL : '');
const opponents = computed(() => state.doc ? state.doc.opponents.sort((a, b) => { return b.count - a.count}) : []);
const partners = computed(() => state.doc ? state.doc.partners.sort((a, b) => { return b.count - a.count}) : []);
const singles = computed(() => state.doc ? state.doc.singles : {...additionalUserFields.singles});
const doubles = computed(() => state.doc ? state.doc.doubles : {...additionalUserFields.doubles});
const joined = computed(() => state.doc ? state.doc.created.toDate().toDateString() : 'N/A');
const lastGame = computed(() => state.doc && state.doc.latestGame ? state.doc.latestGame.played.toDate().toDateString() : 'N/A');

const isLoggedIn = computed(() => {
  if (!state.user) {
    return false;
  }
  if(!state.doc) {
    return false;
  }
  return true;
});

const errorMessage = computed(() => {
  if (!state.error) {
    return '';
  }
  return `${state.error.name}: ${state.error.message} (${state.error.code})`;
});

const hasError = computed(() => {
  return state.error != false;
});
/* #endregion */

export const playerStore = readonly({
  state,
  setUser,
  hasError,
  errorMessage,
  isLoggedIn,
  name,
  email,
  profilePicture,
  opponents,
  partners,
  singles,
  doubles,
  joined,
  lastGame,
});
