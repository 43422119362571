const yearFolder = process.env.VUE_APP_STATS_YEAR ? process.env.VUE_APP_STATS_YEAR : undefined;

const getCollectionPath = (path) => {
    if(!yearFolder) {
      return path
    }
    
    return `${yearFolder}/data/${path}`
}

const getCollectionPathForYear = (path, year) => {
  if(!year) {
    return path
  }

  // 2021 does not live in year folder in firebase (it was the first year when the system was not built for multi-year support)
  if(year === 2021) {
    return path
  }
  
  return `${year}/data/${path}`
}


export { getCollectionPath, getCollectionPathForYear };
