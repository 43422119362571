// TODO: Move this to cloud functions?
import { config } from '@/config';

/**
 *
 * @param {*} msg The message to post
 * @returns void
 * Simple message posting to the channel @sph-pong
 * Fire and forget
 * Skipping pre-flight check
 */
const postSlackMessage = (msg) => {
  if (!config.slack_webhook_url) {
    return;
  }
  var xhr = new XMLHttpRequest();
  xhr.open('POST', config.slack_webhook_url);
  xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded'); // Skip pre-flight check...
  var data = `{"text":"${msg}"}`;

  xhr.send(data);
};

const postSlackMessageWelcome = (name) => {
  postSlackMessage(
    `${name} just joined the stats site! Say hi and challenge them to a game!`
  );
};

const postSlackMessageGame = (gameData) => {
  const winners =
    gameData.winner === 'home'
      ? gameData.homeTeam.map((player) => player.displayName).join(' & ')
      : gameData.awayTeam.map((player) => player.displayName).join(' & ');
  const losers =
    gameData.winner === 'away'
      ? gameData.homeTeam.map((player) => player.displayName).join(' & ')
      : gameData.awayTeam.map((player) => player.displayName).join(' & ');
  const winnerScore =
    gameData.winner === 'home' ? gameData.homeScore : gameData.awayScore;
  const loserScore =
    gameData.winner === 'away' ? gameData.homeScore : gameData.awayScore;
  const homePoints = gameData.homeScore.reduce((a, b) => a + b, 0);
  const awayPoints = gameData.awayScore.reduce((a, b) => a + b, 0);
  const totalPoints = homePoints + awayPoints;
  const hasScore = totalPoints > 0;

  let message = `${winners} just beat ${losers}`;
  if (hasScore) {
    let setScore = [];
    for (let i = 0; i < winnerScore.length; i++) {
      setScore.push(`${winnerScore[i]}-${loserScore[i]}`);
    }
    message += ` with a combined score of ${setScore.join(', ')}`;
  } else {
    //
  }
  postSlackMessage(message);
};

const slackTest = (message) => {
  postSlackMessage(message)
}

export { postSlackMessage, postSlackMessageWelcome, postSlackMessageGame, slackTest };
