<template>
  <div v-if="playerStore.isLoggedIn" class="mini-profile">
    <span>{{playerStore.state.user.displayName}}</span>
    <img class="profile-picture" :src="playerStore.state.user.photoURL" />
    <button v-on:click="signout" >Sign out</button>
  </div>
</template>

<script>
  import { signout } from '../api/authentication'

  export default {
    inject: ['playerStore'],
    setup() {
      return {
        signout
      }
    }
  }
</script>

<style scoped lang="scss">
.mini-profile {
  display: inline-flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
}
img.profile-picture {
  width: 30px;
  height: 30px;
  border-radius: 999px;
}
</style>