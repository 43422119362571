<template>
  <header id="navigation">
    <navigation />
  </header>
  <section class="main">
    <router-view/>
  </section>
</template>

<script>
  import { playerStore } from './store/playerStore'
  import { statsStore } from './store/statsStore'
  import Navigation from './components/Navigation'
  export default {
    provide: {
      playerStore,
      statsStore,
    },
    components: {
      Navigation,
    }
  }
</script>

<style lang="scss">

</style>
